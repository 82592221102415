;

$(document).ready(function() {
	if ($('.js-video').length > 0) {
		$('.js-video').fitVids();
	}

	var $cache = {
		top: $('#top'),
		scrollLinks: $('#home .js-scroll-to'),
		navLinks: $('nav a'),
		bodyHtml: $('body, html'),
		window: $(window)
	}
	
	var clickScroll = false;
	
	$cache.scrollLinks.click(function(e) {
		e.preventDefault();
		
		var $this = $(this);
		var hash = this.hash;
		var $target = $(this.hash);
		
		if ($this.data('target')) {
			$target = $($this.data('target'));
			hash = 'top';
		}
		
		clickScroll = true;
		
		$cache.bodyHtml.animate({scrollTop: $target.offset().top}, 200, function() {
			clickScroll = false;
			
			window.location.hash = hash;
		});
	});
});